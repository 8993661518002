@tailwind base;
@tailwind components;
@tailwind utilities;

.comp-gif {
  background-image: url('./assets/gif/comp-min.gif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1023px) {
  .comp-gif {
    height: auto;
  }
}

.what-dyr {
  background-image: url('./assets//images/img//what-dyr-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

@media screen and (max-width: 1023px) {
  .what-dyr {
    background-image: url('./assets//images/img//what-dyr-bg-mobile.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    overflow: hidden;
    height: 900px;
  }
}

/* .what-dyr-mobile {
  background-image: url('./assets//images/img//what-dyr-bg-mobile.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: -1;
} */
.community {
  background: #e7e7e7;
  background-image: url('./assets/images/img/community.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.contact-bg {
  background-image: url('./assets/images/img/contact-bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}
